<template>
    <div class="p-fluid p-grid p-formgrid">
        <AutoFormField v-for="(i, k) in form" v-model="form[k]" :key="k" @check-valid="checkValid" :force-disabled="viewOnly"/>
		<div class="p-md-12">
			<Button v-if="showSave" icon="pi pi-save" class="p-button-rounded p-button-success p-mr-2" @click="() => {$emit('save');}" :disabled="!isFormValid" />
		</div>
    </div>
</template>

<script>
import AutoFormField from './AutoFormField.vue'
export default {
	props: {
        form: Object,
        showSave: {
            type: Boolean,
            default: true
        },
        viewOnly: {
            type: Boolean,
            default: false
        }
	},
	data() {
		return {
      isFormValid: false,
		};
	},
	methods: {
        isValid(k) {
			let f = this.form[k];
            if (f.mandatory) {
                if (['text', 'p-password', 'password', 'calendar', 'chips', 'rating', 'dropdown', 'textarea', 'checkbox', 'captcha'].includes(f.type)) {
                    if (!f.value) {
                        return false;
                    }
                }
                else if (['number'].includes(f.type)) {
                    if (!f.value && f.value !== 0) {
                        return false;
                    }
                }
                else if (['multiselect'].includes(f.type)) {
                    if (!Array.isArray(f.value) || !f.value.length) {
                        return false;
                    }
                }
            }
            return true;
        },
		checkValid(){
			for (let k in this.form) {
				if (!this.isValid(k)) {
          this.$emit("form-valid", false);
          this.isFormValid = false;
				}
			}
			this.$emit("form-valid", true);
			this.isFormValid = true;
		}
	},
	components: {
        AutoFormField
	},
};
</script>

<style lang="scss" scoped>
</style>
