<template>
    <div>
        <div class="p-timeline-comment">
            <div class="p-timeline-event-separator">
                <div class="p-timeline-event-marker">
                    <div class="custom-marker p-d-flex p-ai-center p-jc-center" >
                        <i class="pi pi-pencil"></i>
                    </div>
                </div>
            </div>
            <div class="p-timeline-event-content">
                <Card>
                    <template #title>
                        {{$t('New comment')}}
                    </template>
                    <template #content>
                        <Textarea class="comment-text" :rows="5" :autoResize="true" v-model="newComment" ></Textarea>
                        <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-mr-2" @click="() => {newComment = ''}" />
                        <Button icon="pi pi-save" class="p-button-rounded p-button-success p-mr-2" @click="addNewComment" />
                    </template>
                </Card>
            </div>
        </div>

        <div class="p-m-2 p-pb-4">
          <Checkbox id="showViewersCheckbox" v-model="showViewersCheckbox" :binary="true"/>
          <label class="p-mx-3 p-text-bold" for="showViewersCheckbox">{{ $t('Show Viewers') }}</label>
        </div>

        <Timeline :value="filteredHistory" align="left">
            <template #marker="slotProps">
                <div class="custom-marker p-d-flex p-ai-center p-jc-center" >
                    <i :class="'pi ' + slotProps.item.icon"></i>
                </div>
            </template>
            <template #content="slotProps">
                <Card>
                    <template #title>
                        {{slotProps.item.type === "activity" ? $t(slotProps.item.content, {email: slotProps.item.author}) : $t('{email} wrote', {email: slotProps.item.author})}}
                    </template>
                    <template #subtitle>
                        {{new Date(slotProps.item.timestamp).toLocaleString()}}
                        <Chip v-if="slotProps.item.edited" :label="$t('Edited on {date}', {date: new Date(slotProps.item.edited).toLocaleString()})" icon="pi pi-pencil" class="p-ml-2" /> 
                    </template>
                    <template #content>
                        <CommentBox :item="slotProps.item" @save="() => $emit('save')"/>
                    </template>
                </Card>
            </template>
        </Timeline>
    </div>
</template>

<script>
import CommentBox from './CommentBox.vue';

export default {
	props: {
		history: Array,
	},
	data() {
		return {
            newComment: "",
            showViewersCheckbox: false,
		};
	},
	methods: {
        addNewComment() {
            this.history.push({
                    type: "comment",
                    timestamp: new Date().toISOString(),
                    icon: "pi-comment",
                    author: this.$auth.user.email,
                    content: this.newComment
                })
            this.newComment = '';
            this.$emit('save');
        }
	},
	components: {
        CommentBox
	},
  computed: {
      filteredHistory: function () {
          return this.history.filter(event => {
            return !(!this.showViewersCheckbox && event.icon === "pi-eye")
          });
      }
  },
};
</script>

<style lang="scss" scoped>
.custom-marker {
    border: 1px solid #DEE2E6;
    height: 30px;
    max-height: 30px;
    width: 30px;
    max-width: 30px;
    border-radius: 15px;
    color: #DEE2E6;
    background-color: #FFFFFF;
}

/deep/ .p-timeline-event-opposite {
    max-width: 0px;
    padding: 0px !important;
}
/deep/ .p-chip {
    border-radius: 33px !important;
    font-size: 75%;
}
/deep/ .p-chip-icon {
    font-size: 85%;
}

.p-timeline-comment {
    display: flex;
    flex-direction: row;
}

.comment-text {
    width: 100%;
}
</style>
