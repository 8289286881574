<template>
	<div>
        <Textarea class="comment-text" v-if="item.type==='comment'" :autoResize="true" v-model="item.content" :disabled="!editing"></Textarea>
        <Button v-if="item.type==='comment' && item.author === $auth.user.email && !editing" icon="pi pi-pencil" class="p-button-rounded p-mr-2" @click="() => {editing = true; backup = item.content;}"/>
        <Button v-if="editing" icon="pi pi-times" class="p-button-rounded p-button-danger p-mr-2" @click="() => {editing = false; item.content = backup;}" />
        <Button v-if="editing" icon="pi pi-save" class="p-button-rounded p-button-success p-mr-2" @click="() => {editing = false; item.edited = new Date().toISOString(); $emit('save')}" />
	</div>
</template>

<script>

export default {
	props: {
		item: Object,
	},
	data() {
		return {
            editing: false,
            backup: ''
		};
	},
	methods: {
	},
	components: {
	},
};
</script>

<style lang="scss" scoped>
.comment-text {
    width: 100%;
}

/deep/ .p-inputtextarea {
    border: none;
}

</style>
